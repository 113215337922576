import { stringify } from "qs";
import { HttpRequestError } from "../exceptions";
import {
  HttpClient,
  HttpMethod,
  Primitive,
  RequestParameters,
  Response,
} from "./HttpClient";

export class DefaultHttpClient implements HttpClient {
  constructor(private readonly basePath?: string) {}

  async get<Res>(config?: RequestParameters<void>): Promise<Res> {
    return this.request("get", config);
  }

  async get2<Res>(config?: RequestParameters<void>): Promise<Response<Res>> {
    return this.request2("get", config);
  }

  async head2(config?: RequestParameters<void>): Promise<Response<void>> {
    return this.request2("head", config);
  }

  async post<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Res> {
    return this.request("post", config);
  }

  async post2<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Response<Res>> {
    return this.request2("post", config);
  }

  async put<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Res> {
    return this.request("put", config);
  }

  async put2<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Response<Res>> {
    return this.request2("put", config);
  }

  async delete<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Res> {
    return this.request("delete", config);
  }

  async delete2<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Response<Res>> {
    return this.request2("delete", config);
  }

  async patch<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Res> {
    return this.request("PATCH", config);
  }

  async patch2<Res = any, Req = any>(
    config?: RequestParameters<Req>
  ): Promise<Response<Res>> {
    return this.request2("PATCH", config);
  }

  async request<Res = any, Req = any>(
    method: HttpMethod,
    config?: RequestParameters<Req>
  ): Promise<Res> {
    return (await this.request2(method, config)).body;
  }

  async request2<Res = any, Req = any>(
    method: HttpMethod,
    config?: RequestParameters<Req>
  ): Promise<Response<Res>> {
    const { path, query, body, headers, skipAutoSerialization, logRequest } =
      config || {};
    const pathDivider =
      !!this.basePath &&
      !!path &&
      !this.basePath.endsWith("/") &&
      !path.startsWith("/")
        ? "/"
        : "";

    // Create query parameters
    const queryParams = query
      ? `?${stringify(query, { arrayFormat: "comma" })}`
      : "";

    // Convert headers
    const finalizedHeaders: Record<string, string> = headers
      ? DefaultHttpClient.stringify(headers)
      : {};

    if (body && !skipAutoSerialization) {
      finalizedHeaders["content-type"] = "application/json";
    }

    // Make the request
    const url = `${this.basePath || ""}${pathDivider}${path}${queryParams}`;
    if (logRequest)
      this.log(method, {
        path: url,
        headers: finalizedHeaders,
        body,
        skipAutoSerialization,
      });
    const response = await fetch(url, {
      method,
      headers: finalizedHeaders,
      body:
        body === undefined || skipAutoSerialization
          ? (body as any)
          : JSON.stringify(body),
    });

    let responseBody: any = undefined;
    const contentType = response.headers.get("content-type");
    if (
      contentType?.includes("application/json") ||
      contentType?.includes("application/hal+json")
    )
      responseBody = await response.json();
    else if (
      ["application/text", "application/xml", "text/"].some((m) =>
        contentType?.includes(m)
      )
    )
      responseBody = await response.text();
    else {
      const blob = await response.blob();
      if (blob.size !== 0) {
        responseBody = blob;
      }
    }

    if (response.ok) {
      return {
        headers: response.headers,
        redirected: response.redirected,
        status: response.status,
        statusText: response.statusText,
        type: response.type,
        url: response.url,
        body: responseBody,
      };
    }

    throw new HttpRequestError(method, response, responseBody);
  }

  private log<T>(
    method: HttpMethod,
    { path, headers, body, skipAutoSerialization }: RequestParameters<T>
  ): void {
    let bodyText = "";
    if (body) {
      if (skipAutoSerialization) {
        bodyText =
          typeof body === "string"
            ? `\n\n\t${body}`
            : "\n\n\t<NON-PRINTABLE REQUEST BODY>";
      } else if (!skipAutoSerialization) {
        bodyText = `\n\n\t${JSON.stringify(body)}`;
      }
    }

    let headerText = "";
    if (Object.keys(headers || {}).length) {
      headerText = Object.entries(headers!)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n\t");
      headerText = `\n\t${headerText}`;
    }

    // eslint-disable-next-line no-console
    console.log(
      `Making HTTP Request:\n\t${method.toUpperCase()} ${path}${headerText}${bodyText}`
    );
  }

  private static stringify(
    input: Record<string, Primitive | Primitive[]>
  ): Record<string, string> {
    return Object.fromEntries(
      Object.entries(input).map(([name, value]) => [
        name.toLowerCase().trim(),
        Array.isArray(value)
          ? value.map((v) => (v === undefined ? "" : v).toString()).join(",")
          : (value === undefined ? "" : value).toString(),
      ])
    );
  }
}
