import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { PropsWithChildren } from "react";
import { LoginButton } from "../LoginButton";

interface Props {
  authenticated: boolean;
  authorities: string[];
  logout: () => void;
}

function NavLink({ link, children }: PropsWithChildren<{ link: string }>) {
  return (
    <Nav.Link
      href={link}
      className={window.location.pathname === link ? "active" : undefined}
    >
      {children}
    </Nav.Link>
  );
}

export function TopMenu({ authenticated, authorities, logout }: Props) {
  const hasReadRight =
    authorities.includes("ADMINISTRATOR") || authorities.includes("READ_WRITE");
  const hasAdmin = authorities.includes("ADMINISTRATOR");
  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container fluid>
        <Navbar.Brand href="#channel-maps">Token Update Server</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <NavLink link="/channels">Channels</NavLink>
            {hasReadRight && (
              <NavLink link="/channel-maps">Channel Maps</NavLink>
            )}
            {hasReadRight && (
              <NavLink link="/firmware-images">Firmware Images</NavLink>
            )}
            {hasAdmin && <NavLink link="/user-roles">User Roles</NavLink>}
          </Nav>

          <Navbar.Text>
            {authenticated ? (
              <Button variant="outline-primary" onClick={logout}>
                Logout
              </Button>
            ) : (
              <LoginButton />
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
