import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { CircleLoader } from "react-spinners";

interface Props {
  fileName?: string;
  content?: string;
  displayName: string;
}

export function InMemoryDownload({ fileName, content, displayName }: Props) {
  if (fileName && content)
    return (
      <a
        download={fileName}
        href={`data:application/octet-stream;name=${fileName};base64,${content}`}
      >
        <FontAwesomeIcon icon={faDownload} /> {displayName}
      </a>
    );
  else
    return (
      <>
        <span style={{ display: "inline", marginRight: "1.5em" }}>
          <CircleLoader color="teal" size="1em" />
        </span>
        Preparing...
      </>
    );
}
