import { NamedError } from "./NamedError";
import { HttpMethod } from "../HttpClient";

export class HttpRequestError extends NamedError {
  public readonly status: number;

  constructor(
    public readonly method: HttpMethod,
    public readonly response: {
      url: string;
      status: number;
      statusText: string;
    },
    public readonly responseBody?: any
  ) {
    super(
      `Failed to make HTTP request: ${method.toUpperCase()} ${
        response.url
      } -> ${response.status} / ${response.statusText}\n${
        !!responseBody ? JSON.stringify(responseBody, null, 2) : "<EMPTY BODY>"
      }`
    );
    this.status = response.status;
  }
}
