import React, { Component } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { CustomJwtPayload, User, UsersResponse } from "../../models";
import { BetterSpinner } from "../BetterSpinner";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { EditRoleModal } from "../EditRoleModal";
import { toHumanReadableRole } from "../../utils";
import { HTTP_CLIENT } from "../../hooks";

interface Props {
  jwt: string;
}

interface State {
  data?: User[];
  error?: Error;
  showEdit: boolean;
  userToEdit?: User;
}

export class Roles extends Component<Props, State> {
  private static readonly PATH_PREFIX = "/api/v2/users";
  private readonly self: string;

  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = { showEdit: false };
    this.self = jwtDecode<CustomJwtPayload>(this.props.jwt).sub!;
  }

  async componentDidMount(): Promise<void> {
    await this.updateUserList();
  }

  render() {
    if (this.state.error) {
      return (
        <Row>
          <Col>
            <h1>Error loading users</h1>
            <p>
              We're sorry, but we were unable to load the list of users due to{" "}
              <code>{this.state.error.name}</code>
            </p>
            <pre>
              <code>{this.state.error.message}</code>
            </pre>
          </Col>
        </Row>
      );
    } else if (this.state.data) {
      return (
        <>
          <Row>
            <Col>
              <h1>User Roles</h1>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table hover striped>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Edit</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.data!.map((user) => (
                    <tr key={user.email}>
                      <td>{user.email}</td>
                      <td>{toHumanReadableRole(user.role)}</td>
                      <td>
                        <Button
                          size="sm"
                          disabled={user.email === this.self}
                          onClick={() => {
                            this.setState({
                              userToEdit: user,
                              showEdit: true,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          <EditRoleModal
            show={this.state.showEdit}
            close={() => this.setState({ showEdit: false })}
            user={this.state.userToEdit}
            onSave={async (user) => {
              try {
                await HTTP_CLIENT.patch({
                  path: `/api/v2/users/${user.email}`,
                  headers: { Authorization: `Bearer ${this.props.jwt}` },
                  body: user,
                });
                await this.updateUserList();
              } catch (e: any) {
                console.error(e.stack);
                toast.error("Failed to save user!");
              }

              this.setState({ showEdit: false });
            }}
          />
        </>
      );
    } else {
      return <BetterSpinner />;
    }
  }

  private async updateUserList(): Promise<void> {
    try {
      const response = await HTTP_CLIENT.get<UsersResponse>({
        path: Roles.PATH_PREFIX,
        headers: { Authorization: `Bearer ${this.props.jwt}` },
      });
      this.setState({ data: response._embedded.users, error: undefined });
    } catch (e: any) {
      console.error(e.stack);
      this.setState({ data: undefined, error: e });
    }
  }
}
