import { Button } from "react-bootstrap";
import React from "react";
import { jwtDecode } from "jwt-decode";
import { CustomJwtPayload } from "../../models";

interface Props {
  jwt: string | null;
  releaseNotes: string | null;
  onClick: () => void;
}

export function ReleaseNotesButton({ jwt, releaseNotes, onClick }: Props) {
  const authorities = jwt ? jwtDecode<CustomJwtPayload>(jwt).authorities : [];
  const editable =
    authorities.includes("ADMINISTRATOR") || authorities.includes("READ_WRITE");

  return (
    <>
      {editable ? (
        <Button size="sm" onClick={onClick}>
          {!!releaseNotes ? "Edit" : "Create"}
        </Button>
      ) : !!releaseNotes ? (
        <Button size="sm" onClick={onClick}>
          View
        </Button>
      ) : null}
    </>
  );
}
