import { Button, Form, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { User } from "../../models";
import { fromHumanReadableRole, toHumanReadableRole } from "../../utils";

interface Props {
  show: boolean;
  close: () => void;
  user?: User;
  onSave: (user: User) => Promise<void> | void;
}

export function EditRoleModal({ show, close, user, onSave }: Props) {
  const [updatedRole, setUpdatedRole] = useState<string>(user?.role || "");

  if (!user) {
    return <></>;
  } else
    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          Edit <code style={{ marginLeft: "1em" }}>{user.email}</code>
        </Modal.Header>

        <Modal.Body>
          <Form.Label>Role</Form.Label>
          <Form.Select
            aria-label="Role"
            onChange={(e) =>
              setUpdatedRole(fromHumanReadableRole(e.target.value))
            }
            value={toHumanReadableRole(updatedRole || user.role)}
          >
            <option>Read Only</option>
            <option>Read Write</option>
            <option>Administrator</option>
          </Form.Select>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type={"submit"}
            onClick={() => onSave({ ...user, role: updatedRole })}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
}
