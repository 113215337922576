import React from "react";
import { Form } from "react-bootstrap";
import { ChannelRankDiff } from "./ChannelRankDiff";
import { NewChannel } from "./models";
import { Channel } from "../../models";

interface Props {
  initialChannelList: Channel[];
  modifiedChannels: NewChannel[];
}

export function ChannelRankDiffList({
  initialChannelList,
  modifiedChannels,
}: Props) {
  return (
    <Form.Text>
      {modifiedChannels
        // Only display channels that are ranked AND have been modified. Be sure to include this one in case we're
        // changing it from ranked to unranked.
        .filter((channel) => {
          const originalChannelState = initialChannelList.filter(
            (anOldChannel) => anOldChannel.id.name === channel.id?.name,
          )[0];
          return channel.stabilityRank !== originalChannelState?.stabilityRank;
        })
        .map((channel) => (
          <div key={`modded-channel-preview-${channel.id?.name}`}>
            {channel.id?.name}{" "}
            <div style={{ float: "right" }}>
              <ChannelRankDiff
                initialChannelList={initialChannelList}
                newChannel={channel}
              />
            </div>
            <br />
          </div>
        ))}
    </Form.Text>
  );
}
