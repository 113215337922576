import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  ChannelMaps,
  Channels,
  FirmwareImages,
  OAuth2RedirectHandler,
  Roles,
  TopMenu,
} from "./components";
import { jwtDecode } from "jwt-decode";
import { CustomJwtPayload } from "./models";
import { ToastContainer } from "react-toastify";

function getInitialJwt(): string | null {
  const initialJwt = window.localStorage.getItem("jwt");
  if (initialJwt) {
    const decodedJwt = jwtDecode<CustomJwtPayload>(initialJwt);
    const expirationTime = decodedJwt.exp || 0;
    const nowInSeconds = new Date().getTime() / 1000;
    if (expirationTime > nowInSeconds) {
      return initialJwt;
    } else {
      console.log("JWT expired. Removing from local storage.");
      window.localStorage.removeItem("jwt");
    }
  }

  return null;
}

export function App() {
  const [jwt, setJwt] = useState(getInitialJwt());
  const [authorities, setAuthorities] = useState<string[]>(
    jwt ? jwtDecode<CustomJwtPayload>(jwt).authorities : [],
  );

  const routes = [
    {
      path: "/",
      element: <Navigate to={"/channels"} />,
    },
    {
      path: "/channels",
      element: <Channels jwt={jwt} />,
    },
    {
      path: "/channel-maps",
      element: <ChannelMaps jwt={jwt} />,
    },
    {
      path: "/firmware-images",
      element: <FirmwareImages jwt={jwt} />,
    },
    {
      path: "/oauth2/redirect",
      element: (
        <OAuth2RedirectHandler
          setJwt={(jwt) => {
            window.localStorage.setItem("jwt", jwt);
            setJwt(jwt);
            setAuthorities(jwtDecode<CustomJwtPayload>(jwt).authorities);
          }}
        />
      ),
    },
  ];

  if (!!jwt) {
    routes.push({
      path: "/user-roles",
      element: <Roles jwt={jwt} />,
    });
  }

  return (
    <>
      <TopMenu
        authenticated={!!jwt}
        authorities={authorities}
        logout={() => {
          setJwt(null);
          setAuthorities([]);
          window.localStorage.removeItem("jwt");
        }}
      />

      <Container fluid>
        <RouterProvider router={createBrowserRouter(routes)} />
      </Container>

      <ToastContainer autoClose={10000} theme="dark" />
    </>
  );
}
