import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Channel,
  FirmwareImage,
  ModelNumber,
  WriteableChannel,
} from "../../models";
import { NewChannel } from "./models";
import { ChannelRankDiffList } from "./ChannelRankDiffList";

interface Props {
  initialChannelList: Channel[];
  firmwareImages?: FirmwareImage[];
  models?: ModelNumber[];
  user: string;
  show: boolean;
  close: () => void;
  initialChannel?: Channel;
  onSave: (newChannel: WriteableChannel) => Promise<void> | void;
  computeNewChannelOrder: <T extends Pick<Channel, "id" | "stabilityRank">>(
    channelToEdit: T,
  ) => T[];
}

interface State {
  newChannel?: Partial<NewChannel>;
  modifiedChannels: NewChannel[];
}

export class AddEditChannelModal extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {
      modifiedChannels: [],
    };
  }

  async componentDidMount(): Promise<void> {}

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.close()}>
        <Modal.Header closeButton>
          {this.props.initialChannel ? "Edit" : "Create"} Channel
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Group controlId="channelName">
                  <Form.Label>Channel Name</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Name"
                    disabled={!!this.props.initialChannel?.id?.name}
                    value={this.getName()?.replaceAll("_-_", "/")}
                    onChange={(e) => {
                      this.setChannelAndModifiedList({
                        ...this.state.newChannel,
                        id: {
                          ...this.state.newChannel?.id,
                          name: e.target.value.replaceAll("/", "_-_"),
                        },
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={6}>
                <Form.Group controlId="model">
                  <Form.Label>Product Line</Form.Label>

                  <Form.Select
                    onChange={(e) => {
                      const model = parseInt(e.target.value);
                      if (!Number.isNaN(model)) {
                        this.setChannelAndModifiedList({
                          id: {
                            ...this.state.newChannel?.id,
                            model,
                          },
                        });
                      }
                    }}
                  >
                    <option value="">-- select model --</option>

                    {/*  TODO: Make this dynamic */}
                    <option value="1">TR2</option>
                    <option value="2">TR3</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={6}>
                <Form.Group controlId="firmwareImage">
                  <Form.Label>Firmware Image</Form.Label>

                  <Form.Select
                    onChange={(e) =>
                      this.setState({
                        newChannel: {
                          ...this.state.newChannel,
                          firmware: e.target.value,
                        },
                      })
                    }
                    value={this.getFirmware()}
                  >
                    <option value="">-- select version --</option>
                    {this.props.firmwareImages?.map((fw) => (
                      <option
                        key={`fw-select-${fw.id}`}
                        value={fw._links.self.href}
                      >
                        {fw.version}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12}>
                <Form.Group controlId="stabilityRank">
                  <Form.Label>Stability Rank</Form.Label>

                  <Form.Control
                    type="number"
                    min={1}
                    max={this.getMaxRank()}
                    value={this.getStabilityRank() || ""}
                    onChange={(e) => {
                      const newRank: number | null =
                        e.target.value.trim().length !== 0
                          ? Number(e.target.value)
                          : null;

                      this.setChannelAndModifiedList({
                        id: {
                          name: this.getName(),
                          model: this.getModel(),
                        },
                        firmware: this.getFirmware(),
                        stabilityRank: newRank,
                      });
                    }}
                  />
                  {this.state.modifiedChannels.length !== 0 && (
                    <ChannelRankDiffList
                      initialChannelList={this.props.initialChannelList}
                      modifiedChannels={this.state.modifiedChannels}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.close()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type={"submit"}
            disabled={!this.getName() || !this.getFirmware()}
            onClick={async () => {
              await this.props.onSave({
                id: { name: this.getName()!, model: this.getModel() },
                lastUpdatedTime: new Date().toISOString(),
                lastUpdatedBy: this.props.user,
                firmware: this.getFirmware()!,
                stabilityRank: this.getStabilityRank() || null,
              });
              this.close();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private close() {
    this.setState({
      newChannel: undefined,
      modifiedChannels: [],
    });
    this.props.close();
  }

  private getName(): string {
    return (
      this.state.newChannel?.id?.name ||
      this.props.initialChannel?.id?.name ||
      ""
    );
  }

  private getModel(): number {
    return (
      this.state.newChannel?.id?.model ||
      this.props.initialChannel?.id?.model ||
      1
    );
  }

  private getFirmware(): string {
    return (
      this.state.newChannel?.firmware ||
      this.props.initialChannel?.firmware?._links.self.href ||
      ""
    );
  }

  private getStabilityRank(): number | null {
    if (this.state.newChannel) {
      return this.state.newChannel.stabilityRank || null;
    }
    return this.props.initialChannel?.stabilityRank || null;
  }

  private getMaxRank(): number {
    const initiallyRankedChannels = this.props.initialChannelList.filter(
      (channel) => channel.stabilityRank !== null,
    );
    return (
      initiallyRankedChannels.length +
      (!!this.props.initialChannel?.stabilityRank ? 0 : 1)
    );
  }

  private setChannelAndModifiedList(newChannel: Partial<NewChannel>) {
    const startingRank = this.props.initialChannel?.stabilityRank || null;
    this.setState({
      newChannel: newChannel,
      modifiedChannels:
        newChannel.stabilityRank !== startingRank
          ? this.props.computeNewChannelOrder({
              ...newChannel,
              id: {
                name: newChannel?.id?.name || "Unnamed",
                model: newChannel?.id?.model || 1,
              },
              stabilityRank: newChannel.stabilityRank || null,
            })
          : [],
    });
  }
}
