import React from "react";
import { Channel } from "../../models";
import { NewChannel } from "./models";

interface Props {
  initialChannelList: Channel[];
  newChannel: NewChannel;
}

function rankToString(rank: number | null): string {
  return rank ? `${rank}` : "Unranked";
}

export function ChannelRankDiff({ initialChannelList, newChannel }: Props) {
  const old: Channel | undefined = initialChannelList.filter(
    (oldChannel) => oldChannel.id.name === newChannel.id?.name,
  )[0];

  if (old?.stabilityRank === newChannel.stabilityRank) {
    return null;
  } else {
    if (old) {
      return (
        <>
          {rankToString(old.stabilityRank)}
          {old.stabilityRank !== newChannel.stabilityRank &&
            ` => ${rankToString(newChannel.stabilityRank)}`}
        </>
      );
    } else {
      return <>{`NEW => ${rankToString(newChannel.stabilityRank)}`}</>;
    }
  }
}
