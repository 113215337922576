import React from "react";
import { HashLoader } from "react-spinners";

export function BetterSpinner() {
  return (
    <div
      style={{
        marginTop: 100,
      }}
    >
      <HashLoader
        color="teal"
        size={100}
        style={{
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
}
