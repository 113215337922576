import React, { useCallback, useState } from "react";
import { BinaryBlob, BinaryBlobResponse, FirmwareImage } from "../../models";
import { InMemoryDownload } from "../InMemoryDownload";
import { HTTP_CLIENT } from "../../hooks";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { ReleaseNotesButton } from "../ReleaseNotesModal";
import { Button } from "react-bootstrap";

const BINARY_BLOBS_PATH_PREFIX = "/api/v2/binaryBlobs";

interface Props {
  fw: FirmwareImage;
  jwt: string | null;
  editReleaseNotesClicked: () => void;
}

interface FwFileList {
  releaseAppOnly?: BinaryBlob;
  releaseAppOnlyElf?: BinaryBlob;
  releaseAppAndBootloader?: BinaryBlob;
  releaseFimeTest?: BinaryBlob;
  releaseMfg?: BinaryBlob;
  releaseMfgElf?: BinaryBlob;
  debugAppOnly?: BinaryBlob;
  debugAppAndBootloader?: BinaryBlob;
  debugFimeTest?: BinaryBlob;
  locationsFile?: BinaryBlob;
  translationsFile?: BinaryBlob;
  bootloaderRelease?: BinaryBlob;
  bootloaderDebug?: BinaryBlob;
  bootloaderReleaseElf?: BinaryBlob;
}

export function FirmwareImageTableRow({
  fw,
  jwt,
  editReleaseNotesClicked,
}: Props) {
  const [fwFilesLoadTriggered, setFwFilesLoadTriggered] = useState(false);
  const [fwFiles, setFwFiles] = useState<FwFileList>({});

  const updateBlobsList = useCallback(async () => {
    const blobsResponse = await HTTP_CLIENT.get<BinaryBlobResponse>({
      path: `${BINARY_BLOBS_PATH_PREFIX}/search/findByFirmware`,
      query: { firmware: fw.id },
    });
    const files = blobsResponse._embedded.binaryBlobs;
    setFwFiles({
      releaseAppOnly: files.find((b) => b.dataTypeRaw === 1),
      releaseAppAndBootloader: files.find((b) => b.dataTypeRaw === 2),
      releaseFimeTest: files.find((b) => b.dataTypeRaw === 3),
      bootloaderRelease: files.find((b) => b.dataTypeRaw === 4),
      debugAppOnly: files.find((b) => b.dataTypeRaw === 5),
      debugAppAndBootloader: files.find((b) => b.dataTypeRaw === 6),
      debugFimeTest: files.find((b) => b.dataTypeRaw === 7),
      bootloaderDebug: files.find((b) => b.dataTypeRaw === 8),
      locationsFile: files.find((b) => b.dataTypeRaw === 9),
      translationsFile: files.find((b) => b.dataTypeRaw === 10),
      releaseAppOnlyElf: files.find((b) => b.dataTypeRaw === 11),
      releaseMfgElf: files.find((b) => b.dataTypeRaw === 12),
      bootloaderReleaseElf: files.find((b) => b.dataTypeRaw === 13),
      releaseMfg: files.find((b) => b.dataTypeRaw === 14),
    });
  }, [fw]);

  const appAndBlName = `token_mcuboot_and_app.${fw.version}.bin`;

  return (
    <tr>
      <td>
        <FontAwesomeIcon
          icon={faThumbTack}
          style={{
            marginRight: "0.8em",
            opacity: fw.pinned ? 1 : 0,
          }}
        />
        <code>{fw.version}</code>
      </td>
      {fwFilesLoadTriggered ? (
        <>
          <td>
            <InMemoryDownload
              displayName="OTA Updatable App"
              fileName={fw.version}
              content={fwFiles.releaseAppOnly?.data}
            />
            {fwFiles.releaseAppAndBootloader && (
              <>
                <br />
                <InMemoryDownload
                  displayName="App + Bootloader"
                  fileName={appAndBlName}
                  content={fwFiles.releaseAppAndBootloader?.data}
                />
              </>
            )}
            {fwFiles.releaseAppOnlyElf && (
              <>
                <br />
                <InMemoryDownload
                  displayName="OTA Updatable App (Elf)"
                  fileName={`${fw.version}.elf`}
                  content={fwFiles.releaseAppOnlyElf?.data}
                />
              </>
            )}
            {fwFiles.releaseFimeTest && (
              <>
                <br />
                <InMemoryDownload
                  displayName="fime_test.bin"
                  fileName="fime_test.bin"
                  content={fwFiles.releaseFimeTest?.data}
                />
              </>
            )}
            {fwFiles.releaseMfg && (
              <>
                <br />
                <InMemoryDownload
                  displayName="Manufacturing"
                  fileName="mfg.bin"
                  content={fwFiles.releaseMfg?.data}
                />
              </>
            )}
            {fwFiles.releaseMfgElf && (
              <>
                <br />
                <InMemoryDownload
                  displayName="Manufacturing (Elf)"
                  fileName="mfg.elf"
                  content={fwFiles.releaseMfgElf?.data}
                />
              </>
            )}
            {fwFiles.bootloaderRelease && (
              <>
                <br />
                <InMemoryDownload
                  displayName="Bootloader"
                  fileName="bootloader.bin"
                  content={fwFiles.bootloaderRelease?.data}
                />
              </>
            )}
            {fw.releaseMeta && (
              <>
                <br />
                <InMemoryDownload
                  displayName="Metadata"
                  fileName="metadata.json"
                  content={window.btoa(fw.releaseMeta)}
                />
              </>
            )}
          </td>
          <td>
            {fwFiles.debugAppOnly && (
              <InMemoryDownload
                displayName="OTA Updatable App"
                fileName={fw.vcsCommit}
                content={fwFiles.debugAppOnly?.data}
              />
            )}
            {fwFiles.debugAppAndBootloader && (
              <>
                <br />
                <InMemoryDownload
                  displayName="App + Bootloader"
                  fileName={appAndBlName}
                  content={fwFiles.debugAppAndBootloader?.data}
                />
              </>
            )}
            {fwFiles.debugFimeTest && (
              <>
                <br />
                <InMemoryDownload
                  displayName="fime_test.bin"
                  fileName="fime_test.bin"
                  content={fwFiles.debugFimeTest?.data}
                />
              </>
            )}
            {fw.debugMeta && (
              <>
                <br />
                <InMemoryDownload
                  displayName="Metadata"
                  fileName="metadata.json"
                  content={window.btoa(fw.debugMeta)}
                />
              </>
            )}
          </td>
          <td>
            {fwFiles.translationsFile && (
              <InMemoryDownload
                displayName="til.json"
                fileName="til.json"
                content={fwFiles.translationsFile?.data}
              />
            )}
            {fwFiles.translationsFile && fwFiles.locationsFile && <br />}
            {fwFiles.locationsFile && (
              <InMemoryDownload
                displayName="li.json"
                fileName="li.json"
                content={fwFiles.locationsFile?.data}
              />
            )}
          </td>
        </>
      ) : (
        <td colSpan={3}>
          <Button
            size="sm"
            onClick={async () => {
              setFwFilesLoadTriggered(true);
              try {
                await updateBlobsList();
              } catch (e: any) {
                toast.error(e.message);
              }
            }}
          >
            Load Files
          </Button>
        </td>
      )}
      <td>
        <ReleaseNotesButton
          jwt={jwt}
          releaseNotes={fw.releaseNotes}
          onClick={editReleaseNotesClicked}
        />
      </td>
      <td>{fw.buildAuthor}</td>
      <td>
        Uploaded: {fw.uploadTime}
        <br />
        Compiled: {fw.buildTime}
      </td>
      <td>
        <a href={fw.buildUrl} target="_blank" rel="noreferrer">
          {fw.vcsCommit.substring(0, 7)}
        </a>
      </td>
    </tr>
  );
}
