import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

interface Props {
  text: ReactNode;
  onHide: () => void;
  show: boolean;
  onConfirm: () => void | Promise<void>;
  confirmationButtonText: string;
}

export function ConfirmationModal({
  text,
  onConfirm,
  show,
  onHide,
  confirmationButtonText,
}: Props) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton={true}>Are you sure?</Modal.Header>

      <Modal.Body>You are about to {text}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>

        <Button variant="danger" type={"submit"} onClick={onConfirm}>
          {confirmationButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
