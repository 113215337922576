import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  setJwt: (jwt: string) => void;
}

export function OAuth2RedirectHandler({ setJwt }: Props) {
  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");
  const jwt = searchParams.get("token");
  const path = searchParams.get("path");

  setTimeout(() => {
    if (error) {
      toast.error(
        <>
          <b>Login error</b>
          <p>{error}</p>
        </>,
        { toastId: error },
      );
    } else if (jwt) {
      setJwt(jwt);
    }
  }, 100);

  return <Navigate to={{ pathname: path || "/" }} />;
}
