import { titleCase } from "title-case";
import { Channel, ChannelId, ChannelMapId } from "../models";

export function toHumanReadableRole(role: string): string {
  return titleCase(role.toLocaleLowerCase().replaceAll("_", " "));
}

export function fromHumanReadableRole(role: string): string {
  return role.toUpperCase().replaceAll(" ", "_");
}

export function toBetterTimeString(dateStr: string): string {
  const date = new Date(dateStr);
  return `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
}

export function compareChannelRank(
  lhs: Pick<Channel, "id" | "stabilityRank">,
  rhs?: Pick<Channel, "id" | "stabilityRank">,
): number {
  if (!rhs) {
    return -1;
  } else if (!!lhs.stabilityRank && !rhs.stabilityRank) {
    return -1;
  } else if (!lhs.stabilityRank && !!rhs.stabilityRank) {
    return 1;
  } else if (!!lhs.stabilityRank && !!rhs.stabilityRank) {
    return lhs.stabilityRank - rhs.stabilityRank;
  } else {
    return lhs.id.name.localeCompare(rhs.id.name);
  }
}

export function serializeChannelId(id: ChannelId): string {
  return `${id.name}+${id.model}`;
}

export function serializeChannelMapId(id: ChannelMapId): string {
  return `${id.channelName}+${id.model}`;
}
