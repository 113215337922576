import React, { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { ModelNumber, ModelNumberResponse } from "../../models";
import { HTTP_CLIENT } from "../../hooks";
import { toast } from "react-toastify";

const PATH_PREFIX = "/api/v2/modelNumbers";

interface Props {
  size: "sm" | "lg";
  onChange: (model: number | undefined) => void;
  value: number | undefined;
}

export function ProductLine({ size = "lg", onChange, value }: Props) {
  const [modelNumbers, setModelNumbers] = useState<ModelNumber[] | undefined>();
  const [loading, setLoading] = useState(false);

  const updateModelNumberList = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const modelNumbersResponse = await HTTP_CLIENT.get<ModelNumberResponse>({
        path: PATH_PREFIX,
      });
      setModelNumbers(modelNumbersResponse._embedded.modelNumbers);
      setLoading(false);
    } catch (e: any) {
      console.error(e.stack);
      setModelNumbers([]);
      setLoading(false);
      toast.error(`Failed to retrieve model numbers due to:\n${e.message}`);
    }
  }, [setModelNumbers, setLoading]);

  if (undefined === modelNumbers && !loading) {
    void updateModelNumberList();
  }

  return (
    <>
      <Form.Group controlId="model">
        <Form.Label>Product Line</Form.Label>

        <Form.Select
          size={size}
          onChange={(e) => {
            if (e.target.value === "") {
              onChange(undefined);
            } else {
              const model = parseInt(e.target.value);
              if (modelNumbers?.map((model) => model.id)?.includes(model)) {
                onChange(model);
              }
            }
          }}
          value={value}
        >
          <option value="">-- select model --</option>

          {modelNumbers?.map((model) => (
            <option key={`model-selector-${model.id}`} value={`${model.id}`}>
              {model.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );
}
